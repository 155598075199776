const TransitionPushLeft = {
  type: 'transition',
  exit: ({ properties }) => ({
    name: 'ExitSlideOffstageLeft',
    time: 0,
    properties: {
      duration: properties.duration,
      fadeOut: !!properties.fade
    }
  }),
  enter: ({ properties }) => ({
    name: 'EnterSlideOffstageRight',
    time: 0,
    properties: {
      duration: properties.duration,
      fadeIn: !!properties.fade
    }
  })
};

export default TransitionPushLeft;
