import * as PIXI from 'pixi.js-legacy';
import EngineObject from './core/EngineObject';
import Composition from './core/Composition';
import Loading from './extra/Loading';

export const TARGET_FPS = 60;

let _options;

export default class Engine extends EngineObject {
  /**
   * Initialization
   *
   * @param {Object} options PIXI Application options
   */
  constructor(options) {
    super();

    _options = options;

    if (!options.Video) {
      console.error('Engine constructor must be passed Video class');
    }

    this.Video = options.Video;

    this.initialize();
  }

  /**
   * Destroy any existing application, listeners, textures, etc.
   */
  cleanup() {
    if (this.app) {
      this.app.destroy(false, true);
      PIXI.utils.destroyTextureCache();
      this.app = null;
    }
  }

  /**
   * Rebuild the engine environment
   */
  initialize() {
    this.cleanup();

    const options = _options || {};

    options['antialias'] = true;
    options['transparent'] = true;
    options['forceCanvas'] = true;

    this.app = new PIXI.Application(options);
    this.app.ticker.add((delta) => {
      this.update((1 / TARGET_FPS) * delta);
    });

    const videos = document.body.getElementsByTagName('video');
    for (let i = 0; i < videos.length; i += 1) {
      document.body.removeChild(videos[i]);
    }

    if (!options['view']) {
      document.body.appendChild(this.app.view); // TODO: Need to cleanup old one?
    }

    this.engine = this;

    this.loading = new Loading();
    /** @var playTransition {Boolean} Whether Scenes should play transitions or not */
    this.playTransition = true;

    this.app.stage.addChild(this.loading);

    this.composition = new Composition(this);
    this.app.stage.addChild(this.composition);
  }

  /**
   * Engine context for Effects
   *
   * @returns {Object}
   */
  get context() {
    return {
      orientation: this.orientation,
      stageWidth: this.stageWidth,
      stageHeight: this.stageHeight
    };
  }

  set duration(value) {
    this.composition.duration = value;
  }

  get duration() {
    return this.composition.duration;
  }

  get stageWidth() {
    return this.app.view.width;
  }

  get stageHeight() {
    return this.app.view.height;
  }

  async setTime(value) {
    if (this._startTime && value < this._startTime) value = this._startTime;

    if (value >= this.duration) {
      value = this.duration;

      if (this.playing) {
        this.pause();

        console.log('Played to end');

        if (this.onEndListener) {
          this.onEndListener();
        }
      }
    }

    await this.composition.setTime(value);

    this.emit('timeline', this.time);
  }

  get time() {
    return this.composition.time;
  }

  /**
   * Convert x position (fraction) to absolute value
   *
   * @param {Number} x Input fraction
   */
  cx(x) {
    return x * this.stageWidth;
  }

  /**
   * Convert y position (fraction) to absolute value
   *
   * @param {Number} y Input fraction
   */
  cy(y) {
    return y * this.stageHeight;
  }

  fromPxX(x) {
    return x / this.stageWidth;
  }

  fromPxY(y) {
    return y / this.stageHeight;
  }

  /**
   * Search the composition for external assets that need to be loaded (images, videos, fonts) and preload them.
   * Parse the composition and convert it to low-level Items and build the complete animation timeline.
   * Resolve the promise.
   *
   * @param {Object} composition
   */
  async load(compositionData, { seekTo = 0, videoFrameServiceUrl } = {}) {
    this.videoFrameServiceUrl = videoFrameServiceUrl;
    this.getVideoFrame = window.getVideoFrame; // Exposed on window via puppeteer in node

    await this.initialize();

    this.composition.visible = false;

    this.loading.visible = true;

    await this.composition.load(compositionData);

    this.composition.visible = true;

    this.loading.visible = false;

    await this.setTime(seekTo);
  }

  /**
   * If startTime is NaN, plays from current time, or, if currentTime is at end of composition, rewinds and plays from 0.
   * If endTime is NaN, plays until end of composition and then pauses at end
   *
   * @param {Number} startTime
   * @param {Number} endTime
   * @param {Boolean} playTransition
   */
  async play(startTime, endTime, playTransition) {
    await this.seek(startTime || this.time);

    super.play(startTime, endTime);

    this.playTransition = playTransition === undefined ? true : playTransition;
    this._startTime = startTime;
    this._endTime = endTime;
    this.composition.play(startTime, endTime);
  }

  /**
   * Pause the composition and effects as well as any videos and animations
   */
  pause() {
    super.pause();
    this.composition.pause();
  }

  /**
   * Seek to time and pause
   * Video elements must seek to the correct time
   *
   * @param {Number} time
   */
  async seek(time) {
    this._startTime = 0;
    this._endTime = NaN;

    await this._seek(time);

    this.emit('timeline', this.time);
  }

  /**
   * Seek to beginning of scene and play until end of scene.
   * If "scene" is NaN, plays the current scene until its end.
   * If playTransition is true, it also plays the transition between the previous scene and the specified scene.
   *
   * @param {Number} layer Layer index
   * @param {Number} scene Scene index inside the layer
   * @param {Boolean} playTransition
   */
  playScene(layer, scene, playTransition) {
    this.composition.playScene(layer, scene, playTransition);
  }

  /**
   * Seek to beginning of scene and pause
   * If "scene" is NaN, seeks to beginning of current scene
   * Video elements must seek to the correct time
   *
   * @param {Number|String} layer
   * @param {Number} scene
   */
  seekScene(layer, scene) {
    this.composition.seekScene(layer, scene);
  }

  /**
   * Deep merges properties with the specified element.
   * This is typically for use when editing, and not while the composition is playing.
   *
   * @param {Number|String} layer Layer name or index
   * @param {Number} scene Scene index in the layer
   * @param {Number} sequence Sequence index in the Scene
   * @param {Number} element Element index in the Sequence
   * @param {Object} data Properties of the element to patch
   */
  async patch(layer, scene, sequence, element, data) {
    return await this.composition.patch(layer, scene, sequence, element, data);
  }

  /**
   * Returns the element's width and height (relative to stage width) at the "rest" phase, before stretchX and stretchY are applied.
   *
   * @param {Number|String} layer Layer name or index
   * @param {Number} scene Scene index in the layer
   * @param {Number} sequence Sequence index in the Scene
   * @param {Number} element Element index in the Sequence
   *
   * @returns {{width: Number, height: Number}}
   */
  getElementSize(layer, scene, sequence, element) {
    return this.composition.getElementSize(layer, scene, sequence, element);
  }

  /**
   * Sets a listener for when the composition reaches the end while playing.
   *
   * @param {Function} listener A callback function that will be called when the composition plays to the end.
   */
  onEnd(listener) {
    this.onEndListener = listener;
  }

  /**
   * Update the engine
   *
   * @param {Number} delta Time delta. Fraction of a second passed from the previos frame
   */
  update(delta) {
    if (this.playing) {
      this.setTime(this.time + delta);
    }
  }
}
