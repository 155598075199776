const DEFAULT_EASE = 'easeOutQuad';

const TransitionPush = {
  type: 'transition',
  exit: ({ properties = {} }) => {
    let { side = 'right', ease = DEFAULT_EASE, duration = 0.5 } = properties;

    return {
      name: 'ExitSlide',
      time: 0,
      properties: {
        side,
        ease,
        duration,
        offStage: true
      }
    };
  },
  enter: ({ properties = {} }) => {
    let { side = 'right', ease = DEFAULT_EASE, duration = 0.5 } = properties;

    if (side === 'right') {
      side = 'left';
    } else if (side === 'left') {
      side = 'right';
    }

    if (side === 'top') {
      side = 'bottom';
    } else if (side === 'bottom') {
      side = 'top';
    }

    return {
      name: 'EnterSlide',
      time: 0,
      properties: {
        side,
        ease,
        duration,
        offStage: true
      }
    };
  }
};

export default TransitionPush;
