const DEFAULT_EASE = 'easeNone';

const TransitionFade = {
  type: 'transition',
  exit: ({ properties = {} }) => {
    const { duration = 0.5 } = properties;

    return {
      name: 'ExitFade',
      time: 0,
      properties: { duration }
    };
  },
  enter: ({ properties = {} }) => {
    const { duration = 0.5 } = properties;

    return {
      name: 'EnterFade',
      time: 0,
      properties: { duration }
    };
  }
};

export default TransitionFade;
