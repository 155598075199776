import * as PIXI from 'pixi.js-legacy';

export default class Loading extends PIXI.Container {
  constructor() {
    super();

    // const loadingText = new PIXI.Text("Loading...", { fill: "#ffffff" });
    // this.addChild(loadingText);
  }
}
