const DEFAULT_EASE = 'easeOutQuad';

const EnterStretch = {
  type: 'enter',
  keyframes: [
    {
      time: 0,
      properties: ({ initial = {}, properties = {} }) => {
        let {
          startStretchX = initial.stretchX,
          startStretchY = initial.stretchY
        } = properties;

        return {
          stretchX: startStretchX,
          stretchY: startStretchY
        };
      }
    },
    {
      time: 1,
      properties: ({ initial = {}, properties = {} }) => {
        let { stretchX = 1, stretchY = 1 } = initial;

        return {
          stretchX: {
            value: stretchX,
            ease: properties.ease || DEFAULT_EASE
          },
          stretchY: {
            value: stretchY,
            ease: properties.ease || DEFAULT_EASE
          }
        };
      }
    }
  ]
};

export default EnterStretch;
