import Engine from './src/Engine';
import Video from './src/elements/Video';

export default Engine;

const canvas = document.getElementById('canvas');
const width = window.innerWidth;
const height = window.innerHeight;

if (canvas.width !== width || canvas.height !== height) {
  console.log({ width, height });
  canvas.width = width;
  canvas.height = height;
}

const engine = new Engine({
  view: canvas,
  width: width,
  height: height,
  Video
});

window.testLoop = async function() {
  const delta = 0.042;

  for (let index = 0; index < 120; index++) {
    await window.engine.seek(index * delta);
  }
};

window.engine = engine;
