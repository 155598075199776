const DEFAULT_EASE = 'easeNone';

const ExitFade = {
  type: 'exit',
  keyframes: [
    {
      time: 1,
      properties: ({ properties = {} }) => {
        let { ease = DEFAULT_EASE } = properties;

        return {
          opacity: {
            value: 0,
            ease
          }
        };
      }
    }
  ]
};

export default ExitFade;
