const DEFAULT_EASE = 'easeInQuad';

const ExitSlide = {
  type: 'exit',
  keyframes: [
    {
      time: 1,
      properties: ({ current = {}, properties = {} }) => {
        const {
          side = 'right',
          offStage = true,
          ease = DEFAULT_EASE
        } = properties;

        const propWithEase = value => ({
          value,
          ease
        });

        const endProps = {};

        if (offStage) {
          switch (side) {
            case 'left':
              endProps.positionX = propWithEase(
                -current.bounds.width + current.bounds.width * current.originX
              );
              break;

            case 'right':
              endProps.positionX = propWithEase(
                1 +
                  current.bounds.width -
                  current.bounds.width * current.originX
              );
              break;

            case 'top':
              endProps.positionY = propWithEase(
                -current.bounds.height + current.bounds.height * current.originY
              );
              break;

            case 'bottom':
              endProps.positionY = propWithEase(
                1 +
                  current.bounds.height -
                  current.bounds.height * current.originY
              );
              break;
          }
        } else {
          switch (side) {
            case 'left':
              endProps.positionX = propWithEase(
                current.positionX - current.bounds.width / 2
              );
              break;

            case 'right':
              endProps.positionX = propWithEase(
                current.positionX + current.bounds.width / 2
              );
              break;

            case 'top':
              endProps.positionY = propWithEase(
                current.positionY - current.bounds.height / 2
              );
              break;

            case 'bottom':
              endProps.positionY = propWithEase(
                current.positionY + current.bounds.height / 2
              );
              break;
          }
        }

        return endProps;
      }
    }
  ]
};

export default ExitSlide;
