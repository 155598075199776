const DEFAULT_EASE = 'easeInQuad';

const ExitStretch = {
  type: 'exit',
  keyframes: [
    {
      time: 1,
      properties: ({ current = {}, properties = {} }) => {
        let {
          endStretchX = current.stretchX,
          endStretchY = current.stretchY
        } = properties;

        return {
          stretchX: {
            value: endStretchX,
            ease: properties.ease || DEFAULT_EASE
          },
          stretchY: {
            value: endStretchY,
            ease: properties.ease || DEFAULT_EASE
          }
        };
      }
    }
  ]
};

export default ExitStretch;
