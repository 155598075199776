import * as PIXI from 'pixi.js-legacy';
import * as ease from 'js-easing-functions';
import { DropShadowFilter } from '@pixi/filter-drop-shadow';
import { MotionBlurFilter } from '@pixi/filter-motion-blur';
import { TwistFilter } from '@pixi/filter-twist';
import { ZoomBlurFilter } from '@pixi/filter-zoom-blur';

import Engine from '../Engine';
import EngineObject from '../core/EngineObject';
import { ElementType } from '../core/types/ElementType';
import { SizeType } from '../core/types/SizeType';
import ShapeRender from '../core/ShapeRender';
import Effect from '../core/Effect';
import { EffectType } from '../core/types/EffectType';

const extraFilters = {
  DropShadowFilter,
  MotionBlurFilter,
  TwistFilter,
  ZoomBlurFilter
};

const TWEENABLE_PROPERTIES = [
  'positionX',
  'positionY',
  'originX',
  'originY',
  'rotation',
  'opacity',
  'stretchX',
  'stretchY'
];

export default class Element extends EngineObject {
  /**
   * @param {Engine} engine
   */
  constructor(engine) {
    super(engine);
    this.name = 'Element';
    /**
     * Initial state for animation + default values
     */
    this.initialState = {
      positionX: 0.5,
      positionY: 0.5,
      opacity: 1,
      originX: 0.5,
      originY: 0.5,
      rotation: 0,
      stretchX: 1,
      stretchY: 1
    };

    this.startTime = 0;

    this.originalData = {};

    this.__bounds = null;

    this._filters = [];

    /** @type {Array<{ time: Number, properties: Object, defaultEase: String}>} */
    this._keyframes = [];

    this._timeline = {};

    this._positionX = 0.5;
    this._positionY = 0.5;

    this._opacity = 1;

    this._originalWidth = engine.stageWidth;
    this._originalHeight = engine.stageHeight;

    this._originX = 0.5;
    this._originY = 0.5;

    this._rotation = 0;

    this._stretchX = 1;
    this._stretchY = 1;

    /** @type {PIXI.DisplayObject} */
    this._subject = null;

    this._backgroundContainer = new PIXI.Container();

    this._elementSize = { width: null, height: null };

    /** @type {ShapeRender} */
    this._background = null;

    this._enterEffects = null;
    this._restEffects = null;
    this._exitEffects = null;

    this.addChild(this._backgroundContainer);
  }

  set bounds(value) {
    this.__bounds = value;
  }

  get bounds() {
    if (this.__bounds) {
      return this.__bounds;
    }

    const pxBounds = this.getBounds();
    return {
      width: this.engine.fromPxX(pxBounds.width),
      height: this.engine.fromPxY(pxBounds.height)
    };
  }

  get current() {
    const result = {};
    for (const k of TWEENABLE_PROPERTIES) {
      result[k] = this[k];
    }

    result['bounds'] = this.bounds;

    return result;
  }

  get elementSize() {
    return this._elementSize;
  }

  /**
   * Apply filters to the element
   *
   * @param {Array} value Filters descriptions
   */
  applyFilters(value) {
    if (Array.isArray(value) && value.length > 0) {
      this._filters = value
        .map(f => {
          if (PIXI.filters[f.name]) {
            return new PIXI.filters[f.name](f.properties);
          } else if (extraFilters[f.name]) {
            return new extraFilters[f.name](f.properties);
          } else {
            return null;
          }
        })
        .filter(f => f != null);
    } else {
      this._filters = [];
    }

    if (this.subject) {
      this.subject.filters = this._filters;
    }
  }

  setTime(value) {
    for (
      let keyframeIndex = 0;
      keyframeIndex < this._keyframes.length - 1;
      keyframeIndex++
    ) {
      const keyframe = this._keyframes[keyframeIndex];
      const nextKeyframe = this._keyframes[keyframeIndex + 1];

      if (value >= keyframe.time && value <= nextKeyframe.time) {
        const inbetweenPhase =
          (value - keyframe.time) / (nextKeyframe.time - keyframe.time);

        const state = {};

        for (const k of TWEENABLE_PROPERTIES) {
          const currentValue =
            keyframe.properties[k] !== undefined
              ? keyframe.properties[k]['value'] !== undefined
                ? keyframe.properties[k]['value']
                : keyframe.properties[k]
              : this.initialState[k];

          const nextValue =
            nextKeyframe.properties[k] !== undefined
              ? nextKeyframe.properties[k]['value'] !== undefined
                ? nextKeyframe.properties[k]['value']
                : nextKeyframe.properties[k]
              : currentValue;

          const easeFuncName = nextKeyframe.properties[k]
            ? nextKeyframe.properties[k]['ease'] || nextKeyframe.defaultEase
            : nextKeyframe.defaultEase;

          const easedPhase =
            easeFuncName === 'easeNone'
              ? inbetweenPhase
              : ease[easeFuncName]
              ? ease[easeFuncName](inbetweenPhase, 0, 1, 1)
              : inbetweenPhase;

          state[k] = currentValue + (nextValue - currentValue) * easedPhase;
        }

        this.setState(state);
      }
    }
    this._time = value;
  }

  get time() {
    return this._time;
  }

  set stretchX(value) {
    if (value !== this._stretchX) {
      this._stretchX = value;
      this.sizeAndPosition();
    }
  }

  get stretchX() {
    return this._stretchX;
  }

  set stretchY(value) {
    if (value !== this._stretchY) {
      this._stretchY = value;

      this.sizeAndPosition();
    }
  }

  get stretchY() {
    return this._stretchY;
  }

  set subject(subject) {
    if (subject !== this._subject) {
      if (this._subject) {
        this.removeChild(this._subject);
      }

      if (subject != null) {
        this.addChild(subject);

        this._subject = subject;
        this._originalWidth = subject.width;
        this._originalHeight = subject.height;

        this.sizeAndPosition();
      }
    }
  }

  get subject() {
    return this._subject;
  }

  set originalDimensions({ width, height }) {
    this._originalWidth = width;
    this._originalHeight = height;

    this.sizeAndPosition();
  }

  set positionX(value) {
    this._positionX = value;
    this.x = this.engine.cx(value);
  }

  get positionX() {
    return this._positionX;
  }

  set positionY(value) {
    this._positionY = value;
    this.y = this.engine.cy(value);
  }

  get positionY() {
    return this._positionY;
  }

  /**
   * Set rotation
   * Overrides PIXI.js implementation
   */
  set rotation(rotation) {
    super.rotation = Math.PI * 2 * rotation;
    this._rotation = rotation;
  }

  /**
   * Get rotation
   */
  get rotation() {
    return this._rotation;
  }

  set opacity(value) {
    this._opacity = value;
    this.alpha = value;
  }

  get opacity() {
    return this._opacity;
  }

  set originX(value) {
    this._originX = value;
  }

  get originX() {
    return this._originX;
  }

  set originY(value) {
    this._originY = value;
  }

  get originY() {
    return this._originY;
  }

  set size(value) {
    this._size = value;
  }

  get size() {
    return this._size;
  }

  /**
   * Assign effects to the Element
   *
   * @param {Array<Effect>} enter List of Enter effects
   * @param {Array<Effect>} rest List of Rest effects
   * @param {Array<Effect>} exit List of Exit effects
   * @param {Boolean} apply Apply effects immediately
   */
  assignEffects(enter, rest, exit, apply) {
    this._enterEffects = enter;
    this._restEffects = rest;
    this._exitEffects = exit;

    if (apply) {
      this.applyEffects();
    }
  }

  /**
   * Apply effects to the Element
   */
  async applyEffects() {
    this.resetKeyframes();

    const enter = this._enterEffects || [];
    const rest = this._restEffects || [];
    const exit = this._exitEffects || [];

    this.setKeyframe(0, { ...this.initialState });

    await this.setTime(0, { applyingEffects: true });

    let segmentTime = 0;

    const durationReducer = (prev, current) =>
      current.duration > prev ? current.duration : prev;

    const enterDuration = enter.reduce(durationReducer, 0);
    const exitDuration = exit.reduce(durationReducer, 0);
    const restDuration = this.duration - (enterDuration + exitDuration);
    const context = this.engine.context;
    const initial = this.initialState;

    const lineup = [
      { effects: enter, duration: enterDuration },
      { effects: rest, duration: restDuration },
      { effects: exit, duration: exitDuration }
    ];

    for (const index in lineup) {
      const segmentDuration = lineup[index].duration;
      const defaultEase = index == 2 ? 'easeInQuad' : 'easeOutQuad';

      if (lineup[index].effects.length == 0) {
        const properties = this._keyframes[this._keyframes.length - 1]
          .properties;
        this.setKeyframe(segmentTime, properties);
        this.setKeyframe(segmentTime + segmentDuration, properties);
      } else {
        for (const effect of lineup[index].effects) {
          const current = this.current;
          const effectKeyframes =
            effect.keyframes instanceof Function
              ? effect.keyframes({
                  context: this.engine.context,
                  initial: this.initialState,
                  properties: effect.properties
                })
              : effect.keyframes;

          for (const effectKeyframe of effectKeyframes) {
            const properties =
              effectKeyframe.properties instanceof Function
                ? effectKeyframe.properties({
                    context,
                    initial,
                    current,
                    properties: effect.properties
                  })
                : effectKeyframe.properties;

            if (effect.type === EffectType.LOOP) {
              const loopTime = effect.duration * effectKeyframe.time;
              let elementKeyframeTime = segmentTime + loopTime;

              while (elementKeyframeTime <= segmentDuration) {
                this.setKeyframe(elementKeyframeTime, properties);
                elementKeyframeTime += loopTime;
              }
            } else {
              const elementKeyframeTime =
                segmentTime + segmentDuration * effectKeyframe.time;

              this.setKeyframe(elementKeyframeTime, properties, defaultEase);
            }
          }
        }
      }

      segmentTime += segmentDuration;

      await this.setTime(this.time + segmentDuration);
    }

    this.setKeyframe(
      this.duration,
      this._keyframes[this._keyframes.length - 1].properties
    );
  }

  /**
   * Remove all keyframes
   */
  resetKeyframes() {
    this._keyframes = [];
    this._timeline = {};
  }

  /**
   * Place or update a keyframe at specific time
   *
   * @param {Number} time Time
   * @param {Object} properties Elements properties at given time
   */
  setKeyframe(time, properties, defaultEase) {
    defaultEase = defaultEase || 'easeInQuad';
    if (this._timeline[time]) {
      for (const k in properties) {
        this._timeline[time]['properties'][k] = properties[k];
      }
      this._timeline[time].defaultEase = defaultEase;
    } else {
      const newKeyframe = {
        time,
        properties: { ...properties },
        defaultEase
      };
      this._keyframes.push(newKeyframe);
      this._timeline[time] = newKeyframe;
    }
  }

  /**
   * Set state of the Element. If newState is ommited then initial state will be restored
   *
   * @param {Object} newState New state
   */
  setState(newState) {
    const excludedFields = ['bounds', 'properties', 'initialState'];
    newState = newState || this.initialState;
    for (const k in newState) {
      if (excludedFields.indexOf(k) === -1) {
        this[k] = newState[k];
      }
    }
  }

  /**
   * Size and position the element
   */
  sizeAndPosition() {
    const subject = this.subject;

    if (subject) {
      if (this.size === SizeType.CONTAIN) {
        const r = Math.max(
          this._originalWidth / this.engine.stageWidth,
          this._originalHeight / this.engine.stageHeight
        );

        subject.width = this._originalWidth / r;
        subject.height = this._originalHeight / r;
      } else if (this.size === SizeType.COVER) {
        this.positionX = 0.5;
        this.positionY = 0.5;

        const r = Math.min(
          this._originalWidth / this.engine.stageWidth,
          this._originalHeight / this.engine.stageHeight
        );

        subject.width = this._originalWidth / r;
        subject.height = this._originalHeight / r;
      } else if (this.size === SizeType.FILL) {
        subject.width = this.engine.cx(1);
        subject.height = this.engine.cy(1);
      } else if (this.size === SizeType.SCALE_DOWN) {
        const r = Math.max(
          this._originalWidth / this.engine.stageWidth,
          this._originalHeight / this.engine.stageHeight
        );

        subject.width = this._originalWidth / r;
        subject.height = this._originalHeight / r;
      } else if (Array.isArray(this.size)) {
        subject.width = this.engine.cx(this.size[0]);
        subject.height = this.engine.cx(this.size[1]);
      } else if (this.size) {
        if (this.type !== ElementType.TEXT) {
          const ratio = this._originalHeight / this._originalWidth;
          const pxSize = this.engine.cx(this.size);
          subject.width = pxSize;
          subject.height = pxSize * ratio;
        } else {
          subject.width = this._originalWidth;
          subject.height = this._originalHeight;
        }
      }

      subject.x = -(subject.width * this.originX);
      subject.y = -(subject.height * this.originY);

      this._elementSize = {
        width: subject.width / this.engine.stageWidth,
        height: subject.height / this.engine.stageHeight
      };

      this.scale.set(this.stretchX, this.stretchY);

      this.updateBackground();

      if (!this.initialState['bounds']) {
        this.initialState['bounds'] = {
          ...this.bounds
        };
      }
    }
  }

  /**
   * Update background position and size
   */
  updateBackground() {
    if (this._background && this.subject) {
      this._background.x = this.subject.x;
      this._background.y = this.subject.y;
      this._background.resize(this.subject.width, this.subject.height);
    }
  }

  /**
   * Create or destroy the background if necessary
   */
  async setupBackground(background) {
    if (this._background) {
      this._backgroundContainer.removeChild(this._background);
    }

    if (background) {
      this._background = new ShapeRender(this.engine, background);

      await this._background.fillShape();

      this._backgroundContainer.addChild(this._background);

      this.updateBackground();
    }
  }

  /**
   * Load Element data
   *
   * @param {Object} data Element data
   */
  async load(data) {
    this.originalData = data;

    const keys = Object.keys(data);
    if (!keys.includes('background')) {
      // In case it had a background and the key was deleted
      await this.setupBackground(null);
    }

    for (const field of keys) {
      this.initialState[field] = data[field];

      if (field === 'background') {
        await this.setupBackground(data[field]);
      } else if (field === 'filters') {
        this.applyFilters(data[field]);
      } else {
        this[field] = data[field];
      }
    }
  }
}
