const DEFAULT_EASE = 'easeOutQuad';

const EnterSlide = {
  type: 'enter',
  keyframes: [
    {
      time: 0,
      properties: ({ initial, current, properties }) => {
        const startProps = {};

        const { side = 'left', offStage = true } = properties;

        if (offStage) {
          switch (side) {
            case 'left':
              startProps.positionX =
                -current.bounds.width + current.bounds.width * initial.originX;
              break;

            case 'right':
              startProps.positionX =
                1 +
                current.bounds.width -
                current.bounds.width * initial.originX;
              break;

            case 'top':
              startProps.positionY =
                -current.bounds.height +
                current.bounds.height * initial.originY;
              break;

            case 'bottom':
              startProps.positionY =
                1 +
                current.bounds.height -
                current.bounds.height * initial.originY;
              break;
          }
        } else {
          switch (side) {
            case 'left':
              startProps.positionX =
                current.positionX - current.bounds.width / 2;
              break;

            case 'right':
              startProps.positionX =
                current.positionX + current.bounds.width / 2;
              break;

            case 'top':
              startProps.positionY =
                current.positionY - current.bounds.height / 2;
              break;

            case 'bottom':
              startProps.positionY =
                current.positionY + current.bounds.height / 2;
              break;
          }
        }

        return startProps;
      }
    },
    {
      time: 1,
      properties: ({ initial = {}, properties = {} }) => ({
        positionX: {
          value: initial.positionX,
          ease: properties.ease || DEFAULT_EASE
        },
        positionY: {
          value: initial.positionY,
          ease: properties.ease || DEFAULT_EASE
        }
      })
    }
  ]
};

export default EnterSlide;
