import * as PIXI from 'pixi.js-legacy';

import Element from './Element';

import Engine, { TARGET_FPS } from '../Engine';

export default class Animation extends Element {
  /**
   * @param {Engine} engine
   */
  constructor(engine) {
    super(engine);
    this.name = 'Animation';
    this.animationDuration = 0;
  }

  /**
   * @param {Number} value
   */
  async setTime(value) {
    this._time = value;

    let inFrame = value;

    while (inFrame >= this.animationDuration) {
      inFrame -= this.animationDuration;
    }

    const frame = Math.floor(
      this.subject.totalFrames * (inFrame / this.animationDuration)
    );

    if (this.properties.loop) {
      await this.subject.gotoAndStop(frame);
    } else {
      if (value > this.animationDuration) {
        await this.subject.gotoAndStop(this.subject.totalFrames - 1);
      }
    }
  }

  get time() {
    return this._time;
  }

  async load(data) {
    await super.load(data);

    await new Promise(async resolve => {
      const loader = new PIXI.Loader();
      loader.add('source', this.properties.source).load((loader, resources) => {
        let textures = [];

        for (let textureName of Object.keys(
          resources['source'].spritesheet.textures
        ).sort((a, b) => {
          return Number(a.replace(/\D/g, '')) - Number(b.replace(/\D/g, ''));
        })) {
          const texture = resources['source'].spritesheet.textures[textureName];
          textures.push(texture);
        }

        this.animationDuration =
          textures.length / (this.properties.fps || TARGET_FPS);
        this.subject = new PIXI.AnimatedSprite(textures);
        this.subject.loop = this.properties.loop;
        resolve();
      });
    });
  }
}
