import EngineObject from './EngineObject';
import Scene from './Scene';

export default class Layer extends EngineObject {
  constructor(engine) {
    super(engine, 'scenes');
    this.scenes = [];
    /** @property {String} name Layer name */
    this.name = 'Untitled Layer';
  }

  /**
   * @returns {Scene}
   */
  childClassFactory() {
    return Scene;
  }

  /**
   * Load layer data
   *
   * @param {Object} data Layer data
   */
  async load(data) {
    let nextStartTime = 0;
    let prevScene = null;

    await super.load(data, async newScene => {
      if (prevScene) {
        prevScene.nextScene = newScene;
      }

      newScene.startTime = nextStartTime;
      newScene.prevScene = prevScene;
      nextStartTime += newScene.duration;
      prevScene = newScene;
    });

    for (const scene of this.scenes) {
      scene.applyTransitions();
    }
  }
}
