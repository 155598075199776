const DEFAULT_EASE = 'easeInOutSine';

const RestSlide = {
  type: 'rest',
  keyframes: [
    {
      time: 1,
      properties: ({ current = {}, properties = {} }) => {
        const propWithEase = value => ({
          value,
          ease: properties.ease || DEFAULT_EASE
        });

        const endProps = {};

        switch (properties.side) {
          case 'left':
            endProps.positionX = propWithEase(
              current.positionX - current.bounds.width / 2
            );
            break;

          case 'top':
            endProps.positionY = propWithEase(
              current.positionY - current.bounds.height / 2
            );
            break;

          case 'bottom':
            endProps.positionY = propWithEase(
              current.positionY + current.bounds.height / 2
            );
            break;

          case 'right':
          default:
            endProps.positionX = propWithEase(
              current.positionX + current.bounds.width / 2
            );
            break;
        }

        return endProps;
      }
    }
  ]
};

export default RestSlide;
