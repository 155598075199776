const ExitSlideRight = {
  type: 'exit',
  keyframes: [
    {
      time: 1,
      properties: ({ properties, current }) => ({
        // Slide right half of the width of the element
        positionX: current.positionX + current.bounds.width / 2,

        // Fade out
        opacity: properties.fadeOut ? 0 : current.opacity
      })
    }
  ]
};

export default ExitSlideRight;
