import * as PIXI from 'pixi.js-legacy';
import RGBAColor from './RGBAColor';
import { FillType } from './types/FillType';
import loadResource from './util/loadResource';

/**
 * Size of the texture to render the gradient
 * Should be power of 2
 * @type {Number}
 */
const GRADIENT_TEXTURE_SIZE = 512;

class GradientPoint {
  constructor(data) {
    this.color = RGBAColor(data['color']);
    this.offset = data['offset'];
    this._cornerRadius = 0;
  }
}

/**
 * Universal class for shape rendering
 */
export default class ShapeRender extends PIXI.Container {
  constructor(engine, properties) {
    super();

    this._background = null;
    this._gradientAngle = 0;

    this.engine = engine;
    this.fillType = FillType.COLOR;

    this.graphics = new PIXI.Graphics();
    this.maskGraphics = new PIXI.Graphics();
    this.backgroundContainer = new PIXI.Container();

    this.addChild(this.backgroundContainer);
    this.addChild(this.graphics);

    if (properties) {
      this.properties = properties;
    }
  }

  set cornerRadius(value) {
    if (Array.isArray(value)) {
      // TODO: Multiple corner radiuses
    } else {
      this._cornerRadius = this.engine.cx(value);
    }
  }

  get cornerRadius() {
    return this._cornerRadius;
  }

  set background(newBackground) {
    if (this._background && this._background !== newBackground) {
      this.backgroundContainer.removeChild(this._background);
    }

    if (newBackground) {
      if (this.width && this.height) {
        if ([FillType.IMAGE, FillType.VIDEO].indexOf(this.fillType) != -1) {
          const r = Math.min(
            newBackground.texture.width / this.width,
            newBackground.texture.height / this.height
          );
          newBackground.width = newBackground.texture.width / r;
          newBackground.height = newBackground.texture.height / r;
          newBackground.x = (this.width - newBackground.width) / 2;
          newBackground.y = (this.height - newBackground.height) / 2;
        } else {
          newBackground.width = this.width;
          newBackground.height = this.height;
        }

        this.backgroundContainer.addChild(newBackground);
      }

      if (this.mask !== this.maskGraphics) {
        this.mask = this.maskGraphics;
        this.addChild(this.maskGraphics);
      }
    } else {
      this.removeChild(this.maskGraphics);
      this.mask = null;
    }

    this._background = newBackground;
  }

  get background() {
    return this._background;
  }

  set backgroundColor(value) {
    if (Array.isArray(value)) {
      this._backgroundColor = value.map((gradientPoint, index) =>
        typeof gradientPoint === 'string'
          ? new GradientPoint({
              color: gradientPoint,
              offset: index / (value.length - 1)
            })
          : new GradientPoint(gradientPoint)
      );
    } else {
      this._backgroundColor = RGBAColor(value);
    }
    this.renderShape();
  }

  get backgroundColor() {
    return this._backgroundColor;
  }

  set borderWidth(value) {
    this._borderWidth = this.engine.cx(value);
    this.renderShape();
  }

  get borderWidth() {
    return this._borderWidth;
  }

  set borderColor(value) {
    this._borderColor = RGBAColor(value);
    this.renderShape();
  }

  get borderColor() {
    return this._borderColor;
  }

  set gradientAngle(value) {
    this._gradientAngle = value;
    this.renderShape();
  }

  get gradientAngle() {
    return this._gradientAngle;
  }

  set path(value) {
    this._path = value;
  }

  get path() {
    return this._path;
  }

  set properties(value) {
    for (const f in value) {
      this[f] = value[f];
    }
    this.renderShape();
  }

  get width() {
    return this._width;
  }

  set width(value) {
    if (this._width !== value) {
      this._width = value;
      this.renderShape();
    }
  }

  get height() {
    return this._height;
  }

  set height(value) {
    if (this._height !== value) {
      this._height = value;
      this.renderShape();
    }
  }

  /**
   * Loads data for filling the background
   */
  async fillShape() {
    switch (this.fillType) {
      case FillType.COLOR:
        // N/A
        break;
      case FillType.IMAGE:
        const imageResource = await loadResource(this.fill.source, 'image');
        this.background = new PIXI.Sprite(PIXI.Texture.from(imageResource));
        break;
      case FillType.VIDEO:
        const videoResource = await loadResource(this.fill.source, 'video');
        this.background = new PIXI.Sprite(PIXI.Texture.from(videoResource));
        break;
      default:
        console.error('Unknown fill type: %s', this.fillType);
    }
  }

  /**
   * Render the shape
   */
  renderShape() {
    if (this.width && this.height) {
      this.cacheAsBitmap = false;
      const graphics = [this.graphics, this.maskGraphics];

      for (const g of graphics) {
        g.clear();
      }

      if (this.borderColor && this.borderWidth) {
        this.graphics.lineStyle(
          this.borderWidth,
          this.borderColor.int,
          this.borderColor.alpha,
          1
        );

        this.maskGraphics.lineStyle(this.borderWidth, 0x0, 1, 1);
      }

      this.maskGraphics.beginFill(0x000000, 1);

      if (this.fillType === FillType.COLOR) {
        if (Array.isArray(this.backgroundColor)) {
          if (this.background === null) {
            const halfTextureSize = GRADIENT_TEXTURE_SIZE;
            const textureSize = halfTextureSize * 2;
            const canvas = document.createElement('canvas');
            canvas.width = canvas.height = textureSize;

            const ctx = canvas.getContext('2d');
            const a = Math.PI * 2 * this.gradientAngle;
            const gradient = ctx.createLinearGradient(
              halfTextureSize - Math.cos(a) * halfTextureSize,
              halfTextureSize - Math.sin(a) * halfTextureSize,
              halfTextureSize + Math.cos(a) * halfTextureSize,
              halfTextureSize + Math.sin(a) * halfTextureSize
            );

            for (const stop of this.backgroundColor) {
              gradient.addColorStop(stop.offset, stop.color.rgbaFunc);
            }

            ctx.fillStyle = gradient;
            ctx.fillRect(0, 0, textureSize, textureSize);

            this.background = new PIXI.Sprite(PIXI.Texture.from(canvas));
          } else {
            this.background = this.background;
          }
        } else {
          if (this.backgroundColor) {
            this.graphics.beginFill(
              this.backgroundColor.int,
              this.backgroundColor.alpha
            );
          }
        }
      } else {
        this.background = this.background;
      }

      if (Array.isArray(this.path)) {
        for (const g of graphics) {
          g.drawPolygon(
            this.path.map((n, index) =>
              (index + 1) % 2 === 0 ? this.height * n : this.width * n
            )
          );
        }
      } else {
        for (const g of graphics) {
          if (this.cornerRadius === 0) {
            g.drawRect(0, 0, this.width, this.height);
          } else {
            g.drawRoundedRect(0, 0, this.width, this.height, this.cornerRadius);
          }
        }
      }
      this.cacheAsBitmap = true;
    }
  }

  /**
   * Resize and re-render the shape
   *
   * @param {Number} newWidth New shape Width
   * @param {Number} newHeight New shape Height
   */
  resize(newWidth, newHeight) {
    if (this._width !== newWidth || this._height !== newHeight) {
      this._width = newWidth;
      this._height = newHeight;

      if (this.background != null) {
        this.background = this.background;
      }

      this.renderShape();
    }
  }
}
