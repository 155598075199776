const DEFAULT_EASE = 'easeInQuad';

const ExitTransform = {
  type: 'exit',
  keyframes: [
    {
      time: 1,
      properties: ({ current, properties }) => {
        let {
          fade = false,
          endRotation = current.rotation,
          endStretchX = current.stretchX,
          endStretchY = current.stretchY
        } = properties;

        return {
          opacity: {
            value: fade ? 0 : current.opacity,
            ease: 'easeNone'
          },
          rotation: {
            value: endRotation,
            ease: properties.ease || DEFAULT_EASE
          },
          stretchX: {
            value: endStretchX,
            ease: properties.ease || DEFAULT_EASE
          },
          stretchY: {
            value: endStretchY,
            ease: properties.ease || DEFAULT_EASE
          }
        };
      }
    }
  ]
};

export default ExitTransform;
