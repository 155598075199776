import Element from './Element';
import ShapeRender from '../core/ShapeRender';

export default class Shape extends Element {
  constructor(engine) {
    super(engine);
    this.name = 'Shape';
  }

  async load(data) {
    const newShape = new ShapeRender(this.engine);
    newShape.properties = data['properties'];
    await super.load(data);
    await newShape.fillShape();
    this.subject = newShape;
  }
}
