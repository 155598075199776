const DEFAULT_EASE = 'easeOutQuad';

const EnterRotate = {
  type: 'enter',
  keyframes: [
    {
      time: 0,
      properties: ({ initial = {}, properties = {} }) => {
        let { rotation = 1 } = initial;

        let { turns = 0 } = properties;

        return {
          rotation: turns ? turns : rotation
        };
      }
    },
    {
      time: 1,
      properties: ({ initial, properties }) => {
        const propWithEase = value => ({
          value,
          ease: properties.ease || DEFAULT_EASE
        });

        let { rotation = 0 } = initial;

        return {
          rotation: propWithEase(rotation)
        };
      }
    }
  ]
};

export default EnterRotate;
