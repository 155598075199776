const ExitSlideOffstageLeft = {
  type: 'exit',
  keyframes: [
    {
      time: 1,
      properties: ({ current, properties }) => ({
        positionX:
          -current.bounds.width + current.bounds.width * current.originX,

        opacity: properties.fadeOut ? 0 : current.opacity
      })
    }
  ]
};

export default ExitSlideOffstageLeft;
