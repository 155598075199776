import effects from '../effects';
import { EffectType } from './types/EffectType';
import Effect from './Effect';

export default class Transition {
  constructor(engine, data) {
    const properties = data['properties'] || {};

    this.name = data['name'];
    this.enterEffect = null;
    this.exitEffect = null;
    this.properties = properties;

    if (
      effects[this.name] &&
      effects[this.name].type === EffectType.TRANSITION
    ) {
      const enterData = effects[this.name].enter({ properties });
      const exitData = effects[this.name].exit({ properties });

      this.enterEffect = Effect.factory(engine, enterData);
      this.exitEffect = Effect.factory(engine, exitData);
    } else {
      console.error('Transition effect does not exists: %s', this.name);
    }
  }
}
