const DEFAULT_EASE = 'easeOutQuad';

const EnterZoom = {
  type: 'enter',
  keyframes: [
    {
      time: 0,
      properties: ({ initial = {}, properties = {} }) => {
        let {
          stretchX = 1,
          stretchY = 1,
          positionX = 0.5,
          positionY = 0.5
        } = initial;
        let { startZoom = 1, zoomFromCenter = true } = properties;

        return {
          stretchX: stretchX * startZoom,
          stretchY: stretchY * startZoom,
          positionX: zoomFromCenter ? 0.5 : positionX,
          positionY: zoomFromCenter ? 0.5 : positionY
        };
      }
    },
    {
      time: 1,
      properties: ({ initial, properties }) => {
        const propWithEase = value => ({
          value,
          ease: properties.ease || DEFAULT_EASE
        });

        let {
          stretchX = 1,
          stretchY = 1,
          positionX = 0.5,
          positionY = 0.5
        } = initial;

        return {
          stretchX: propWithEase(stretchX),
          stretchY: propWithEase(stretchY),
          positionX: propWithEase(positionX),
          positionY: propWithEase(positionY)
        };
      }
    }
  ]
};

export default EnterZoom;
