import effects from '../effects';

export default class Effect {
  constructor(engine, name, data, properties) {
    this.engine = engine;
    this.name = name;

    this.keyframes = [];

    for (const prop in data) {
      this[prop] = data[prop];
    }

    this.duration = properties['duration'] || 0.5;

    this.properties = properties;
  }

  /**
   * Effect factory
   *
   * @param {Object} effectData Effect data from a composition file or a Transition
   */
  static factory(engine, effectData) {
    if (effectData) {
      if (effects[effectData['name']]) {
        return new Effect(
          engine,
          effectData['name'],
          effects[effectData['name']],
          effectData['properties'] || {}
        );
      } else {
        console.error('Unknown effect: %s', effectData['name']);
      }
    }

    return null;
  }
}
