const ExitZoomOut = {
  type: 'exit',
  keyframes: [
    {
      time: 1,
      properties: ({ current, properties }) => ({
        stretchX: 0,

        stretchY: 0,

        positionX: properties.zoomToCenter ? 0.5 : current.positionX,

        positionY: properties.zoomToCenter ? 0.5 : current.positionY,

        opacity: properties.fadeOut ? 0 : current.opacity
      })
    }
  ]
};

export default ExitZoomOut;
