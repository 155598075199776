const EnterSlideOffstageLeft = {
  type: 'enter',
  keyframes: [
    {
      time: 0,
      properties: ({ initial, current, properties }) => ({
        // Position off left edge of stage just enough so it is completely off, but no more
        positionX:
          -current.bounds.width + current.bounds.width * initial.originX,

        opacity: properties.fadeIn ? 0 : initial.opacity
      })
    },
    {
      time: 1,
      properties: ({ initial, current, properties }) => ({
        positionX: { value: current.positionX, ease: properties.ease },

        opacity: current.opacity
      })
    }
  ]
};

export default EnterSlideOffstageLeft;
