const EASE = {
  smooth: ['easeInOutQuad', 'easeInOutQuad'],
  breathe: ['easeOutQuad', 'easeInQuad']
};

const withDefaults = ({ amount = 10, style = 'smooth' }) => ({ amount, style });

const LoopPulse = {
  type: 'loop',
  keyframes: [
    {
      time: 0.5,
      properties: ({ current, properties }) => {
        const { amount, style } = withDefaults(properties);

        const stretch = currentStretch =>
          Math.max(0, currentStretch * (1 - amount / 10));

        return {
          stretchX: {
            value: stretch(current.stretchX),
            ease: EASE[style][0]
          },
          stretchY: {
            value: stretch(current.stretchY),
            ease: EASE[style][0]
          }
        };
      }
    },
    {
      time: 1,
      properties: ({ current, properties }) => {
        const { amount, style } = withDefaults(properties);

        const stretch = currentStretch =>
          Math.max(0, currentStretch * (1 / (1 - amount / 10)));

        return {
          stretchX: {
            value: stretch(current.stretchX),
            ease: EASE[style][1]
          },
          stretchY: {
            value: stretch(current.stretchY),
            ease: EASE[style][1]
          }
        };
      }
    }
  ]
};

export default LoopPulse;
