const EnterSlideOffstageRight = {
  type: 'enter',
  keyframes: [
    {
      time: 0,
      properties: ({ initial, current, properties }) => ({
        // Position off right edge of stage just enough so it is completely off, but no more
        positionX:
          1 + current.bounds.width - current.bounds.width * initial.originX,

        opacity: properties.fadeIn ? 0 : initial.opacity
      })
    },
    {
      time: 1,
      properties: ({ initial }) => {
        return {
          positionX: initial.positionX,

          opacity: initial.opacity
        };
      }
    }
  ]
};

export default EnterSlideOffstageRight;
