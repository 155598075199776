import * as PIXI from 'pixi.js-legacy';

import Effect from './Effect';
import Element from '../elements/Element';
import Engine from '../Engine';
import EngineObject from './EngineObject';
import Sequence from './Sequence';
import Transition from './Transition';

export default class Scene extends EngineObject {
  /**
   * @param {Engine} engine
   */
  constructor(engine) {
    super(engine, 'sequences');
    this._freezeTime = null;
    this._transition = null;

    /** @property {Scene} nextScene Next scene */
    this.nextScene = null;

    /** @property {Scene} prevScene Previous scene */
    this.prevScene = null;

    /** @property {Array<Sequence>} sequences */
    this.sequences = [];
    this.startTime = 0;

    const sceneElement = new Element(engine);

    sceneElement.bounds = { width: 1, height: 1 };
    sceneElement.subject = new PIXI.Container();
    sceneElement.initialState.originX = 0.5;
    sceneElement.initialState.originY = 0.5;
    sceneElement.initialState.positionX = 0.5;
    sceneElement.initialState.positionY = 0.5;
    sceneElement.originX = 0.5;
    sceneElement.originY = 0.5;
    sceneElement.positionX = 0.5;
    sceneElement.positionY = 0.5;
    sceneElement.setState();
    this.sceneElement = sceneElement;
    this.childrenContainer.x = -engine.cx(0.5);
    this.childrenContainer.y = -engine.cy(0.5);
    this.childrenContainer = this.sceneElement.subject;
    this.addChild(this.sceneElement);
  }

  set duration(value) {
    this._duration = value;
    this.sceneElement.duration = value;
    for (const sequence of this.sequences) {
      sequence.duration = value;
    }
  }

  get duration() {
    return this._duration;
  }

  /**
   * @param {Number} value
   */
  async setTime(value) {
    this._time = value;

    await this.sceneElement.setTime(value);

    value =
      this._freezeTime === null ? value : Math.min(value, this._freezeTime);

    await this.timeChildren(value);
  }

  get time() {
    return this._time;
  }

  /**
   * @param {Object} transition
   */
  set transition(transition) {
    if (transition) {
      this._transition = new Transition(this.engine, transition);
    }
  }

  /**
   * @returns {Transition}
   */
  get transition() {
    return this._transition;
  }

  applyTransitions() {
    if (this.transition) {
      if (this.nextScene && this.nextScene.transition) {
        this.freeze();
        this.duration += this.nextScene.transition.enterEffect.duration;
      }

      this.sceneElement.assignEffects(
        [this.transition.enterEffect],
        [],
        [],
        true
      );

      if (this.prevScene && this.prevScene.transition === null) {
        this.prevScene.freeze();
        this.prevScene.duration += this.transition.exitEffect.duration;
        this.prevScene.sceneElement.assignEffects(
          [],
          [],
          [this.transition.exitEffect],
          true
        );
      }
    }
  }

  /**
   * @returns {Sequence}
   */
  childClassFactory() {
    return Sequence;
  }

  freeze() {
    this._freezeTime = this.duration;
  }

  /**
   * Load scene data
   *
   * @param {Object} data Scene data
   */
  async load(data) {
    await super.load(
      data,
      async newSequence => {
        newSequence.duration = this.duration;
      },
      true
    );
  }
}
