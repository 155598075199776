import * as PIXI from 'pixi.js-legacy';
import FontFaceObserver from 'fontfaceobserver';

import Element from './Element';
import RGBAColor from '../core/RGBAColor';

const loadedFonts = [];
const SCALE_FACTOR = 1;

export default class Text extends Element {
  constructor(engine) {
    super(engine);

    this.background = null;

    this.name = 'Text';
  }

  set properties(props) {
    let lineHeight;

    const {
      dropShadow = false,
      dropShadowAlpha = 1,
      dropShadowAngle = Math.PI / 6,
      dropShadowBlur = 0,
      dropShadowColor = 'black',
      dropShadowDistance = 0.005
    } = props['style'];

    const textDropShadow = {
      dropShadow,
      dropShadowAlpha,
      dropShadowAngle,
      dropShadowBlur: this.engine.cx(dropShadowBlur),
      dropShadowColor,
      dropShadowDistance: this.engine.cx(dropShadowDistance)
    };

    const fontSize =
      (props['style']['size'] ? this.engine.cx(props['style']['size']) : 14) *
      SCALE_FACTOR;

    if (props['style']['lineHeight']) {
      lineHeight = fontSize * props['style']['lineHeight'];
    }

    const borderWidth = this.background
      ? this.background['borderWidth'] || 0
      : 0;

    const padding = Math.min(
      (this.size * 0.95) / 2 + 0.5 * borderWidth,
      (props['padding'] ? props['padding'] : 0) + 0.5 * borderWidth
    );

    const color = RGBAColor(props['style']['color'] || '0xffffffff');

    const wordWrapWidth =
      this.engine.cx(this.size - padding * 2) * SCALE_FACTOR;

    const textStyle = {
      align: props['style']['align'],
      fill: color.int,
      fontFamily: this.fontFamily,
      fontSize,
      fontWeight: props['style']['weight'] || 'normal',
      letterSpacing: props['style']['letterSpacing'] || 0,
      lineHeight,
      breakWords: true,
      wordWrap: true,
      wordWrapWidth,
      ...textDropShadow
    };

    const textField = new PIXI.Text(props['text'], textStyle);
    textField.alpha = color.alpha;

    const textureWidth =
      (this.engine.cx(this.size) + this.engine.cx(padding) * 2) * SCALE_FACTOR;
    const textureHeight =
      textField.height + this.engine.cx(padding) * SCALE_FACTOR * 2;
    const textTexture = PIXI.RenderTexture.create({
      width: textureWidth,
      height: textureHeight
    });

    let textFieldX = (textureWidth - textField.width) / 2;
    if (textStyle.align === 'left') {
      textFieldX = this.engine.cx(padding) * SCALE_FACTOR;
    } else if (textStyle.align === 'right') {
      textFieldX =
        textureWidth - textField.width - this.engine.cx(padding) * SCALE_FACTOR;
    }
    const textFieldY = this.engine.cx(padding) * SCALE_FACTOR;
    const matrix = new PIXI.Matrix();
    matrix.translate(textFieldX, textFieldY);

    this.engine.app.renderer.render(textField, textTexture, true, matrix);

    const textSprite = new PIXI.Sprite(textTexture);
    textSprite.scale.set(1 / SCALE_FACTOR, 1 / SCALE_FACTOR);
    this.subject = textSprite;
  }

  /**
   * Load text data. Asyncronously load Goole Fonts
   *
   * @param {Object} data Text data
   */
  async load(data) {
    this.fontFamily = '';

    this.background = data['background'] || null;

    const properties = data['properties'] || {};
    const style = properties['style'] || {
      align: 'center',
      font: ['Arial', 'sans'],
      color: '0xFFFFFFFF',
      size: 0.1,
      weight: 400,
      style: null,
      lineHeight: 1
    };
    const font = style['font'] || null;

    const fonts = [];

    if (font) {
      for (const fontName of font) {
        // TODO: Font from orbitrary url
        if (fontName.slice(0, 7) === 'google:') {
          const [
            googleFontName,
            googleFontWeight = 400,
            googleFontTestChars = 'BESbwy'
          ] = fontName.slice(7).split(':');
          if (loadedFonts.indexOf(googleFontName) == -1) {
            const googleFontLink = document.createElement('link');
            googleFontLink.href = `https://fonts.googleapis.com/css?family=${encodeURIComponent(
              googleFontName
            )}:${googleFontWeight}&display=swap`;
            googleFontLink.rel = 'stylesheet';
            document.head.appendChild(googleFontLink);
            const googleFont = new FontFaceObserver(googleFontName);
            try {
              await googleFont.load(googleFontTestChars, 8000);
              console.log(
                `Font loaded: "${googleFontName}" weight: ${googleFontWeight}`
              );
              loadedFonts.push(googleFontName);
            } catch (error) {
              console.warn(
                'Font presence is not guaranteed (%s)',
                googleFontName
              );
            }
          }
          fonts.push(googleFontName);
        } else {
          fonts.push(fontName);
        }
      }
    }

    this.fontFamily = fonts
      .map(f => (f.split(' ').length === 1 ? f : `"${f}"`))
      .join(', ');

    await super.load(data);
  }
}
