const DEFAULT_EASE = 'easeOutQuad';

const EnterTransform = {
  type: 'enter',
  keyframes: [
    {
      time: 0,
      properties: ({ initial, properties }) => {
        let {
          fade = false,
          startRotation = initial.rotation,
          startStretchX = initial.stretchX,
          startStretchY = initial.stretchY
        } = properties;

        return {
          opacity: fade ? 0 : initial.opacity,
          rotation: startRotation,
          stretchX: startStretchX,
          stretchY: startStretchY
        };
      }
    },
    {
      time: 1,
      properties: ({ initial, properties }) => {
        let { opacity = 1, rotation = 0, stretchX = 1, stretchY = 1 } = initial;

        return {
          opacity: {
            value: opacity,
            ease: 'easeNone'
          },
          rotation: {
            value: rotation,
            ease: properties.ease || DEFAULT_EASE
          },
          stretchX: {
            value: stretchX,
            ease: properties.ease || DEFAULT_EASE
          },
          stretchY: {
            value: stretchY,
            ease: properties.ease || DEFAULT_EASE
          }
        };
      }
    }
  ]
};

export default EnterTransform;
