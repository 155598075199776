import * as PIXI from 'pixi.js-legacy';

export default class EngineObject extends PIXI.Container {
  /**
   *
   * @param {Engine} engine
   * @param {String} childrenField Field name for the collection of child elements e.g. `scenes`, `elements`, etc.
   */
  constructor(engine, childrenField) {
    super();

    if (childrenField) {
      this[childrenField] = [];
      this.childrenField = childrenField;
      this.childrenContainer = this;
    }

    this.engine = engine;
    this.playing = false;

    this._duration = 0;
    this._endOffset = 0;
    this._startOffset = 0;
    this._startTime = 0;
    this._time = 0;
  }

  /**
   * Object's duration minus offset time
   *
   * @returns {Number}
   */
  get absoluteDuration() {
    return this.duration - (this.startOffset + this.endOffset);
  }

  /**
   * @param {number} value
   */
  set duration(value) {
    this._duration = value;
  }

  get duration() {
    return this._duration;
  }

  set endOffset(value) {
    this._endOffset = value;
  }

  get endOffset() {
    return this._endOffset;
  }

  set startOffset(value) {
    this._startOffset = value;
  }

  get startOffset() {
    return this._startOffset;
  }

  set startTime(value) {
    this._startTime = value;
  }

  get startTime() {
    return this._startTime;
  }

  /**
   * Set time
   * @param {number} value
   */
  async setTime(value) {
    this._time = value;

    if (this.childrenField) await this.timeChildren();
  }

  /**
   * Get time
   */
  get time() {
    return this._time;
  }

  /**
   * Show children if it's their time to show up
   */
  async timeChildren(time) {
    time = time || this.time;
    for (const child of this[this.childrenField]) {
      if (
        time >= child.startTime + child.startOffset &&
        time <= child.startTime + child.duration - child.endOffset
      ) {
        child.visible = true;
        await child.setTime(time - (child.startTime + child.startOffset));
      } else {
        child.visible = false;
      }
    }
  }

  /**
   * In inherited classes should return a Class to instantiate children elements
   *
   * @param {Object} data Raw data for the element
   */
  childClassFactory(data) {
    return null;
  }

  /**
   * Load data of an object
   *
   * @param {Object} data Data to load
   * @param {Function} initChild Optional function that will be called with a new child element created passed as a parameter
   * @param {Boolean} afterLoad If `true` then `initChild` will be called after new child's `load` function and before otherwise
   */
  async load(data, initChild, afterLoad) {
    if (this.childrenField) {
      let childrenData = [];

      for (const field of Object.keys(data)) {
        if (this.childrenField && field === this.childrenField) {
          for (const existingElement of this[this.childrenField]) {
            this.childrenContainer.removeChild(existingElement);
          }

          this[this.childrenField] = [];

          childrenData = data[this.childrenField];
        } else {
          this[field] = data[field];
        }
      }

      for (const childData of childrenData) {
        const ChildClass = this.childClassFactory(childData);

        if (ChildClass) {
          const newChild = new ChildClass(this.engine);

          if (initChild && afterLoad) {
            await initChild(newChild);
          }

          const response = await newChild.load(childData);

          if (initChild && !afterLoad) {
            await initChild(newChild);
          }

          this[this.childrenField].push(newChild);

          this.childrenContainer.addChild(newChild);
        }
      }
    } else {
      console.error("childrenField isn't set. Impossible to load data");
    }
  }

  /**
   * Play
   *
   * @param {Number} startTime Start time
   * @param {Number} endTime End time
   */
  play(startTime, endTime) {
    this.playing = true;
    if (this.childrenField) {
      for (const child of this[this.childrenField]) {
        child.play(startTime, endTime);
      }
    }
  }

  /**
   * Pause the composition and effects as well as any videos and animations
   */
  pause() {
    this.playing = false;

    if (this.childrenField) {
      for (const element of this[this.childrenField]) {
        element.pause();
      }
    }
  }

  /**
   * Seek
   *
   * @param {Number} time Time to seek to
   */
  async seek(time) {
    await this._seek(time);
  }

  // /**
  //  * The timeout is to trick the Javascript event loop, so
  //  * that the seek actually awaits for the canvas to be painted
  //  * since calling setTimeout(fn, 0) schedules the execution
  //  * of the function to the next loop. Can be replaced
  //  * by setImmediate(https://developer.mozilla.org/en-US/docs/Web/API/Window/setImmediate)
  //  * once it is supported correctly
  //  */
  // async _seek(time) {
  //   await new Promise(resolve => {
  //     setTimeout(async () => {
  //       await this.setTime(time);

  //       resolve();
  //     }, 0);
  //   });
  // }
  async _seek(time) {
    await this.setTime(time);
  }

  /**
   * Update the object on time progress
   *
   * @param {Number} delta Time passed from the previous frame
   */
  update(delta) {
    for (const child of this.children) {
      if (child['update']) {
        child.update(delta);
      }
    }
  }
}
