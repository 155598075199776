import * as PIXI from 'pixi.js-legacy';

import Element from './Element';
import Engine from '../Engine';
import loadResource from '../core/util/loadResource';

export default class Image extends Element {
  /**
   * @param {Engine} engine
   */
  constructor(engine) {
    super(engine);
    this.name = 'Image';
  }

  /**
   * Load image data
   *
   * @param {Object|String} data Data to load. Image url or element data
   */
  async load(data) {
    await super.load(data);
    const imageUrl = data['properties']['source'] || data;
    const imageData = await loadResource(imageUrl, 'image');
    this.subject = new PIXI.Sprite(PIXI.Texture.from(imageData));
  }
}
