import Color from "color";

export default function RGBAColor(hexStr) {
  const rgbStr = hexStr.slice(2, 8);
  const rgb = Color(`#${rgbStr}`);
  const int = rgb.rgbNumber();
  const alpha = Number.parseInt(hexStr.slice(8), 16) / 255;
  const rgbaFunc = `rgba(${rgb.red()}, ${rgb.green()}, ${rgb.blue()}, ${alpha})`;

  return {
    int,
    rgb,
    alpha,
    rgbaFunc
  };
}
