const DEFAULT_EASE = 'easeNone';

const EnterFade = {
  type: 'enter',
  keyframes: [
    {
      time: 0,
      properties: () => {
        return {
          opacity: 0
        };
      }
    },
    {
      time: 1,
      properties: ({ initial = {}, properties = {} }) => {
        let { opacity = 1 } = initial;
        let { ease = DEFAULT_EASE } = properties;

        return {
          opacity: {
            value: opacity,
            ease
          }
        };
      }
    }
  ]
};

export default EnterFade;
