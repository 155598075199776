const DEFAULT_EASE = 'easeInQuad';

const ExitZoom = {
  type: 'exit',
  keyframes: [
    {
      time: 1,
      properties: ({ current = {}, properties = {} }) => {
        const propWithEase = value => ({
          value,
          ease: properties.ease || DEFAULT_EASE
        });

        let { endZoom = 1, zoomToCenter = true } = properties;

        return {
          stretchX: propWithEase(endZoom * current.stretchX),
          stretchY: propWithEase(endZoom * current.stretchY),
          positionX: propWithEase(zoomToCenter ? 0.5 : current.positionX),
          positionY: propWithEase(zoomToCenter ? 0.5 : current.positionY)
        };
      }
    }
  ]
};

export default ExitZoom;
