const EASE = [
  'easeInSine',
  'easeOutSine',
  'easeInOutSine',
  'easeInCubic',
  'easeOutCubic',
  'easeInOutCubic',
  'easeInQuint',
  'easeOutQuint',
  'easeInOutQuint',
  'easeInCirc',
  'easeOutCirc',
  'easeInOutCirc',
  'easeInElastic',
  'easeOutElastic',
  'easeInOutElastic',
  'easeInQuad',
  'easeOutQuad',
  'easeInOutQuad',
  'easeInQuart',
  'easeOutQuart',
  'easeInOutQuart',
  'easeInExpo',
  'easeOutExpo',
  'easeInOutExpo',
  'easeInBack',
  'easeOutBack',
  'easeInOutBack',
  'easeInBounce',
  'easeOutBounce',
  'easeInOutBounce'
];

const randomInt = (max = 10, min = 0) =>
  Math.round(Math.random() * (max - min) + min);

const randomEase = () => EASE[randomInt(EASE.length - 1)];

const Jitter = {
  type: 'rest',
  keyframes: () => {
    const numKeyframes = randomInt(12, 6);
    const keyFrameLength = 1 / numKeyframes;

    const keyframes = [{ time: 0, properties: {} }];
    for (let i = 1; i < numKeyframes; i++) {
      keyframes.push({
        time: i * keyFrameLength,
        properties: {
          positionX: {
            value: Math.random(),
            ease: randomEase()
          },
          positionY: {
            value: Math.random(),
            ease: randomEase()
          }
        }
      });
    }
    return keyframes;
  }
};

export default Jitter;
