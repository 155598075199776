const DEFAULT_EASE = 'easeOutQuad';

const TransitionSlideOver = {
  type: 'transition',
  exit: ({ properties = {} }) => {
    return {
      name: 'Null',
      time: 0
    };
  },
  enter: ({ properties = {} }) => {
    let { side = 'right', ease = DEFAULT_EASE, duration = 0.5 } = properties;

    return {
      name: 'EnterSlide',
      time: 0,
      properties: {
        side,
        ease,
        duration,
        offStage: true
      }
    };
  }
};

export default TransitionSlideOver;
