import Engine from '../Engine';
import EngineObject from './EngineObject';
import Effect from './Effect';

import Animation from '../elements/Animation';
import Element from '../elements/Element';
import Image from '../elements/Image';
import Shape from '../elements/Shape';
import Text from '../elements/Text';
// import Video from "../elements/Video";

import { ElementType } from './types/ElementType';
import { SequenceTimingType } from './types/SequenceTimingType';

export default class Sequence extends EngineObject {
  /**
   * Sequence constructor
   *
   * @param {Engine} engine Engine
   */
  constructor(engine) {
    super(engine, 'elements');

    this.Video = engine.Video;

    this._enterEffect = [];
    this._restEffect = [];
    this._exitEffect = [];

    this._timing = {
      type: SequenceTimingType.SERIAL,
      overlap: 0,
      staggerIn: 0,
      staggerOut: 0
    };
  }

  initEffect(effectData) {
    return (Array.isArray(effectData)
      ? effectData.map(data => Effect.factory(this.engine, data))
      : [Effect.factory(this.engine, effectData)]
    ).filter(e => e !== null);
  }

  /**
   * Set Enter effect
   */
  set enterEffect(effectData) {
    this._enterEffect = this.initEffect(effectData);
  }

  get enterEffect() {
    return this._enterEffect;
  }

  /**
   * Set Exit effect
   */
  set exitEffect(effectData) {
    this._exitEffect = this.initEffect(effectData);
  }

  get exitEffect() {
    return this._exitEffect;
  }

  /**
   * Set Rest effect
   */
  set restEffect(effectData) {
    this._restEffect = this.initEffect(effectData);
  }

  get restEffect() {
    return this._restEffect;
  }

  set timing(value) {
    this._timing = value;
  }

  get timing() {
    return this._timing;
  }

  /**
   * Create and element based on its type
   *
   * @param {Object} data Element's data
   *
   * @returns {Animation|Image|Shape|Text|Video|Element}
   */
  childClassFactory(data) {
    switch (data['type']) {
      case ElementType.ANIMATION:
        return Animation;
      case ElementType.IMAGE:
        return Image;
      case ElementType.SHAPE:
        return Shape;
      case ElementType.TEXT:
        return Text;
      case ElementType.VIDEO:
        return this.Video;
    }

    console.warn('Unknown ElementType: %s', data['type']);

    return Element;
  }

  /**
   * Load Sequence data
   *
   * @param {Object} data Data
   */
  async load(data) {
    let nextStartTime = 0;
    let elementIndex = 0;
    let elementsCount = data['elements'].length;
    await super.load(data, newElement => {
      const staggerIn = this.timing.staggerIn * elementIndex || 0;
      const staggerOut = this.timing.staggerOut * elementIndex || 0;
      const staggerTime = staggerIn - staggerOut;

      if (this.timing.type === SequenceTimingType.PARALLEL) {
        newElement.startTime = staggerIn;
        newElement.duration = this.absoluteDuration - staggerTime;
      } else {
        newElement.startTime = nextStartTime + staggerIn;
        newElement.duration =
          this.absoluteDuration / elementsCount - staggerTime;
      }

      newElement.assignEffects(
        this.enterEffect,
        this.restEffect,
        this.exitEffect
      );

      newElement.applyEffects();

      newElement.setState();

      nextStartTime += newElement.duration;
      elementIndex++;
    });
  }
}
