const DEFAULT_EASE = 'easeInQuad';

const ExitRotate = {
  type: 'exit',
  keyframes: [
    {
      time: 1,
      properties: ({ current, properties }) => {
        let { turns = current.rotation } = properties;

        return {
          rotation: {
            value: turns,
            ease: properties.ease || DEFAULT_EASE
          }
        };
      }
    }
  ]
};

export default ExitRotate;
