import * as PIXI from 'pixi.js-legacy';
import EngineObject from './EngineObject';
import Layer from './Layer';
import Engine from '../Engine';
import { Orientation } from './types/Orientation';
import RGBAColor from './RGBAColor';
import Element from '../elements/Element';

export default class Composition extends EngineObject {
  /**
   * @param {Engine} engine
   */
  constructor(engine) {
    super(engine, 'layers');

    this._background = new PIXI.Graphics();
    this.addChild(this._background);

    this.name = '';
    this.layers = [];
    this.orientation = Orientation.LANDSCAPE;
    this.layersByName = {};
  }

  set background(value) {
    const rgba = RGBAColor(value);
    this._background.clear();
    this._background.beginFill(rgba.int, rgba.alpha);
    this._background.drawRect(
      0,
      0,
      this.engine.stageWidth,
      this.engine.stageHeight
    );
    this._background.endFill();
  }

  /**
   * @param {Number} value
   */
  async setTime(value) {
    if (value > this.duration) {
      value = this.duration;
    }

    this._time = value;

    for (const layer of this.layers) {
      await layer.setTime(this.time);
    }
  }

  /**
   * @returns {Number}
   */
  get time() {
    return this._time;
  }

  /**
   * @returns {Layer}
   */
  childClassFactory() {
    return Layer;
  }

  /**
   * Load composition
   *
   * @param {Object} composition Composition data
   */
  async load(composition) {
    PIXI.utils.clearTextureCache();

    await super.load(composition, (newLayer) => {
      newLayer.startTime = 0;

      newLayer.duration = this.duration;

      if (newLayer['name']) this.layersByName[newLayer.name] = newLayer;
    });

    console.log(
      `Composition "${this.name}" loaded. Orientation: ${this.orientation}`
    );
  }

  /**
   * Seek to beginning of scene and pause
   * If "scene" is NaN, seeks to beginning of current scene
   * Video elements must seek to the correct time
   *
   * @param {Number|String} layer
   * @param {Number} sceneIndex
   */
  seekScene(layer, sceneIndex) {
    const compositionLayer = Number.isInteger(layer)
      ? this.layers[layer]
      : this.layersByName[layer];

    const scene = compositionLayer.scenes[sceneIndex];
    this.engine.seek(scene.startTime);
  }

  /**
   * Seek to beginning of scene and play until end of scene.
   * If "scene" is NaN, plays the current scene until its end.
   * If playTransition is true, it also plays the transition between the previous scene and the specified scene.
   *
   * @param {Number|String} layer Layer index
   * @param {Number} sceneIndex Scene index inside the layer
   * @param {Boolean} playTransition
   */
  playScene(layer, sceneIndex, playTransition) {
    const compositionLayer = Number.isInteger(layer)
      ? this.layers[layer]
      : this.layersByName[layer];

    const scene = compositionLayer.scenes[sceneIndex];
    this.engine.play(
      scene.startTime,
      scene.startTime + scene.duration,
      playTransition
    );
  }

  /**
   * Get Element by its "address"
   *
   * @param {Number|String} layer Layer name or index
   * @param {Number} scene Scene index in the layer
   * @param {Number} sequence Sequence index in the Scene
   * @param {Number} element Element index in the Sequence
   */
  getElement(layer, scene, sequence, element) {
    const compositionLayer = Number.isInteger(layer)
      ? this.layers[layer]
      : this.layersByName[layer];
    return compositionLayer.scenes[scene].sequences[sequence].elements[element];
  }

  /**
   * Returns the element's width and height (relative to stage width) at the "rest" phase, before stretchX and stretchY are applied.
   *
   * @param {Number|String} layer Layer name or index
   * @param {Number} scene Scene index in the layer
   * @param {Number} sequence Sequence index in the Scene
   * @param {Number} element Element index in the Sequence
   *
   * @returns {{width: Number, height: Number}}
   */
  getElementSize(layer, scene, sequence, element) {
    const targetElement = this.getElement(layer, scene, sequence, element);
    return targetElement.elementSize;
  }

  /**
   * Deep merges properties with the specified element.
   * This is typically for use when editing, and not while the composition is playing.
   *
   * @param {Number|String} layer Layer name or index
   * @param {Number} scene Scene index in the layer
   * @param {Number} sequence Sequence index in the Scene
   * @param {Number} element Element index in the Sequence
   * @param {Object} data Properties of the element to patch
   */
  async patch(layer, scene, sequence, element, data) {
    /** @type {Element} */
    const elementToPatch = this.getElement(layer, scene, sequence, element);

    const patchObject = (original, patch) => {
      if (typeof patch === 'object') {
        const result = { ...original };
        for (const k in patch) {
          if (patch[k] === null || typeof patch[k] === 'undefined') {
            delete result[k];
          } else if (typeof patch[k] === 'object' && !Array.isArray(patch[k])) {
            result[k] = patchObject(original[k], patch[k]);
          } else {
            result[k] = patch[k];
          }
        }
        return result;
      } else {
        return patch;
      }
    };

    if (elementToPatch) {
      const patchedData = patchObject(elementToPatch.originalData, data);
      const currentElementTime = elementToPatch.time;
      await elementToPatch.load(patchedData);
      elementToPatch.applyEffects();
      await elementToPatch.setTime(currentElementTime);
    } else {
      throw new Error('Element not found');
    }
  }
}
