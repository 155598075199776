import * as PIXI from 'pixi.js-legacy';

/**
 * Load Image or video resource
 *
 * @param {String} url Resource url
 * @param {String} type Resource type: 'image' or 'video'
 */
export default async function loadResource(url, type) {
  console.log(`Loading resource <${type}> from <${url}>`);

  const loader = new PIXI.Loader();

  const resourceName = `resource_${new Date().getTime()}`;

  if (['image', 'video'].indexOf(type) === -1)
    throw new Error(`Invalid resource type ${type}`);

  const loadType =
    type === 'image'
      ? PIXI.LoaderResource.LOAD_TYPE.IMAGE
      : PIXI.LoaderResource.LOAD_TYPE.VIDEO;

  return new Promise((resolve, reject) => {
    loader
      .add(resourceName, url, {
        loadType,
        crossOrigin: 'anonymous'
      })
      .load((loader, resources) => {
        resolve(resources[resourceName].data);
      })
      .once('error', error => {
        reject(error);
      });
  });
}
