import * as PIXI from 'pixi.js-legacy';
import Element from './Element';
import enableInlineVideo from 'iphone-inline-video';

const TARGET_FPS = 60;
const TIME_PER_FULL_ANIMATION_CYCLE = 1000;

// 60 frames every 2000ms
const DELAY_BETWEEN_VIDEO_AND_CANVAS_RENDER =
  TIME_PER_FULL_ANIMATION_CYCLE / TARGET_FPS;

export default class Video extends Element {
  constructor(engine) {
    super(engine);
    this.name = 'Video';
  }

  setTime(value) {
    return new Promise((resolve) => {
      const prevTime = this.time;
      this._time = value;

      function seekListener(e) {
        e.target.removeEventListener('seeked', seekListener);

        setTimeout(resolve, DELAY_BETWEEN_VIDEO_AND_CANVAS_RENDER);
      }

      this.video.addEventListener('seeked', seekListener);

      if (!this.playing) {
        let videoTime = value;

        while (videoTime > this.video.duration) {
          videoTime -= this.video.duration;
        }

        this.video.currentTime = videoTime;

        this.subject.texture.update();
      } else if (value < prevTime) {
        this.video.currentTime = 0;
      } else {
        resolve();
      }
    });
  }

  get time() {
    return this._time;
  }

  /**
   * Play video
   *
   * @param {Number} startTime Start time (ignored)
   * @param {Number} endTime End time (ignored)
   */
  play(startTime, endTime) {
    super.play(startTime, endTime);
    this.video.play();
  }

  /**
   * Pause video
   */
  pause() {
    super.pause();
    this.video.pause();
  }

  /**
   * Load video data
   *
   * @param {Object|String} data Video data or video url
   */
  async load(data) {
    if (this.video) {
      try {
        document.body.removeChild(this.video);
        this.video = null;
      } catch (err) {
        console.log('Video.js load(): Error removing video tag');
      }
    }

    const videoUrl = data['properties']['source'] || data;

    await super.load(data);

    // this.video = await loadResource(videoUrl, "video");

    var video = document.createElement('video');

    video.setAttribute('crossOrigin', 'anonymous');
    video.setAttribute('playsinline', '');
    video.setAttribute('webkit-playsinline', '');
    // video.setAttribute('muted', '');

    var src = document.createElement('source');
    src.setAttribute('src', videoUrl);
    src.setAttribute('type', 'video/mp4');

    video.appendChild(src);

    video.style.position = 'fixed';
    video.style.left = '0px';
    video.style.top = window.innerHeight + 10 + 'px';
    video.style.zIndex = -10000;
    video.style.opacity = 0.0000001;
    document.body.append(video);

    await new Promise((resolve) => {
      video.onloadeddata = () => {
        resolve();
      };

      video.load();
    });

    this.video = video;

    // this.video.muted = !(this.properties.sound === true);

    const videoTexture = PIXI.Texture.from(video, {
      resourceOptions: { autoPlay: false, updateFPS: 30 }
    });

    const dimensionsUpdatedListener = onTextureUpdate.bind(this);

    videoTexture.addListener('update', dimensionsUpdatedListener);

    // videoTexture.baseTexture.autoPlay = false;
    enableInlineVideo(videoTexture.baseTexture.resource.source, false);

    this.video.pause();

    this.subject = new PIXI.Sprite(videoTexture);

    async function onTextureUpdate() {
      videoTexture.removeListener('update', dimensionsUpdatedListener);

      const { width, height } = videoTexture.orig;

      this.originalDimensions = { width, height };

      await this.setTime(0);
    }
  }
}
